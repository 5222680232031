import { PropsWithChildren, useEffect, useRef } from "react";

interface ModalProps {
  icon?: string;
  id?: string;
  title?: string;
  onClose: () => void;
}

let onkeydownBackup: any;
export default function CenteredModal(props: PropsWithChildren<ModalProps>) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current) modalRef.current.style.opacity = "1";

    onkeydownBackup = window.onkeydown;
    window.onkeydown = onCloseListener;

    return () => {
      window.onkeydown = onkeydownBackup;
    };
  }, []);

  function onCloseListener(e: KeyboardEvent) {
    if (e.keyCode === 27) {
      handleClose();
    }
  }

  function handleClose() {
    if (modalRef.current) {
      modalRef.current.style.opacity = "0";
      modalRef.current.style.display = "none";
    }

    setTimeout(() => {
      window.onkeydown = null;
      if (props.onClose) props.onClose();
    }, 0);
  }

  function handleCloseByClick(event: any) {
    if (
      event.target &&
      event.target.className &&
      typeof event.target.className === "string" &&
      event.target.className.includes("centered-modal")
    ) {
      handleClose();
    }
  }

  return (
    <div
      ref={modalRef}
      className={"modal fade active-modal centered-modal"}
      id={props.id}
      role="dialog"
      style={{ display: "block", background: "rgba(0, 0, 0, 0.4)" }}
      onClick={handleCloseByClick}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {props.icon && <i className={props.icon + " me-2"} />}
              {props.title}
            </h5>
            <button
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            />
          </div>
          <div className="modal-body p-0">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
