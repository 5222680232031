import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AttributeDetailData } from "types/settingTypes";

interface DataSliceState {
  socials?: AttributeDetailData[];
  websites?: AttributeDetailData[];
}

const initialState: DataSliceState = {
  socials: undefined,
  websites: undefined,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    setSocials: (state, { payload }: PayloadAction<AttributeDetailData[]>) => {
      state.socials = payload;
      return state;
    },
    setWebsites: (state, { payload }: PayloadAction<AttributeDetailData[]>) => {
      state.websites = payload;

      return state;
    },
  },
});

export const { setSocials, setWebsites } = settingsSlice.actions;

export default settingsSlice.reducer;
