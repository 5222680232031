import { languages } from "helpers/refs";

export function validateEmail(email: string) {
  if (!email) return false;

  const re =
    /^(?=.{6,265}$)(-|_)*[^\W_](?:[+\w.-]*[^\W_])*(-|_|\.)*@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,13}|[0-9]{1,3})\]?$/;
  return re.test(email);
}

export function valdiateMultilanguageText(values: any) {
  return languages.current?.some((lang) => {
    return !values[lang.isoCode];
  });
}

export function validatePhone(phone: string) {
  return (phone || "").length >= 10;
}

export function validateName(name: string) {
  if (!name) return null;

  let parts = name.split(" ");
  return parts.length > 1 && parts[1].trim().length > 0 ;
}

export function validateTCKN(identityNumber: string) {
  const tc: any = identityNumber;
  var single = 0,
    double = 0,
    result = 0,
    TCTotal = 0,
    i = 0,
    errorStack = [
      11111111110, 22222222220, 33333333330, 44444444440, 55555555550,
      66666666660, 7777777770, 88888888880, 99999999990,
    ];

  if (
    tc.length != 11 ||
    isNaN(parseInt(tc)) ||
    tc[0] == 0 ||
    errorStack.includes(parseInt(tc))
  )
    return false;

  single =
    parseInt(tc[0]) +
    parseInt(tc[2]) +
    parseInt(tc[4]) +
    parseInt(tc[6]) +
    parseInt(tc[8]);
  double =
    parseInt(tc[1]) + parseInt(tc[3]) + parseInt(tc[5]) + parseInt(tc[7]);

  single = single * 7;
  result = Math.abs(single - double);
  if (result % 10 != tc[9]) return false;

  for (var i = 0; i < 10; i++) {
    TCTotal += parseInt(tc[i]);
  }

  if (TCTotal % 10 != tc[10]) return false;

  return true;
}

export function formatIban(iban: string) {
  return (iban || "")
    .replace(/[^\dA-Z]/g, "")
    .replace(/(.{4})/g, "$1 ")
    .trim()
    .substring(0, 19);
}
