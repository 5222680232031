import React from "react";
import ReactDOM from "react-dom/client";
import Feature from "./Main";
import { BrowserRouter } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "../src/style/css/feather.css";
import "../src/style/icon/tabler-icons/webfont/tabler-icons.css";
import "../src/index.scss";
import store from "./redux/store";
import { Provider } from "react-redux";

import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../src/style/icon/boxicons/boxicons/css/boxicons.min.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <React.Suspense fallback={false}>
    <Provider store={store}>
      <BrowserRouter>
        <Feature />
      </BrowserRouter>
    </Provider>
  </React.Suspense>
  // </React.StrictMode>
);
