import { CSSProperties } from "react";

interface Image {
  className?: string;
  src: string;
  alt?: string;
  height?: number;
  width?: number;
  id?: string;
  style?: CSSProperties;
}

const ImageWithBasePath = (props: Image) => {
  // Combine the base path and the provided src to create the full image source URL

  return (
    <img
      className={props.className}
      src={
        props.src.startsWith("http")
          ? props.src
          : `${process.env.REACT_APP_STATIC_URL}${props.src}`
      }
      height={props.height}
      alt={props.alt}
      width={props.width}
      id={props.id}
      style={props.style}
    />
  );
};

export default ImageWithBasePath;
