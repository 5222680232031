import { createSlice } from "@reduxjs/toolkit";
import { userRefrence } from "environment";
import { UserInterface } from "interface";
import cities from "json/cities_counties.json";

interface UserState {
  data: UserInterface;
  pages: PageData[];
  pageMap: any;
}

interface PageData {
  name: string;
  page_id: string;
  read: number;
  write: number;
  delete: number;
  special?: string;
}

const getDefaultState = (): UserState => {
  let user: any = localStorage.getItem("user");
  let pages: any = localStorage.getItem("p");
  try {
    if (user && pages) {
      try {
        user = JSON.parse(decodeURIComponent(window.atob(user)));
        //@ts-ignore
        userRefrence.current = user;
      } catch (error) {
        localStorage.removeItem("user");
      }

      pages = JSON.parse(decodeURIComponent(window.atob(pages)));
      const pageMap = getPageMap(pages);

      return {
        data: user || false,
        pages,
        pageMap,
      };
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("p");
      //@ts-ignore
      return { data: false, pages: [], pageMap: {} };
    }
  } catch (error) {
    //@ts-ignore
    return { data: false, pages: [], pageMap: {} };
  }
};

function getPageMap(pages: PageData[]) {
  const returnVal: any = {
    profile: { read: true, write: true, canReach: true },
  };

  pages.forEach((page) => {
    returnVal[page.page_id] = {
      canReach: page.read == 1 || page.write == 1 || page.delete == 1,
      permissions: {
        read: page.read == 1,
        write: page.write == 1,
        delete: page.delete == 1,
      },
    };

    if (page.special) {
      const specialPermissions = JSON.parse(page.special);
      for (const key in specialPermissions) {
        returnVal[page.page_id].permissions[key] = specialPermissions[key] == 1;
      }
    }
  });

  return returnVal;
}

const userSlice = createSlice({
  name: "user",
  initialState: getDefaultState,
  reducers: {
    setUser: (state, { payload }) => {
      let cityName = "",
        districtName = "";
      if (payload.city) {
        const findedCity = cities.find((c) => c.value == payload.city);
        cityName = findedCity?.label || "";

        if (payload.district && findedCity) {
          districtName =
            findedCity.districts.find((d) => d.value == payload.district)
              ?.label || "";
        }
      }

      payload.cityName = cityName;
      payload.districtName = districtName;

      state.data = payload;
      localStorage.setItem(
        "user",
        window.btoa(encodeURIComponent(JSON.stringify(payload)))
      );
    },
    setUserAccessPages: (state, { payload }) => {
      state.pages = payload;
      state.pageMap = getPageMap(payload);
      return state;
    },
    logout: (state) => {
      //@ts-ignore
      state.data = false;
      state.pageMap = {};
      state.pages = [];
      localStorage.removeItem("token");
      localStorage.removeItem("p");
      localStorage.removeItem("user");
    },
  },
});

export const { setUser, setUserAccessPages, logout } = userSlice.actions;

export default userSlice.reducer;
