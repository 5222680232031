import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/store";
import { setImageModal } from "redux/uiSlice";

let onkeydownBackup: any;
export default function ImageModal() {
  const dispatch = useDispatch();
  const source = useAppSelector((state) => state.ui.imageModal);

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (source) {
      if (modalRef.current) modalRef.current.style.opacity = "1";

      onkeydownBackup = window.onkeydown;
      window.onkeydown = onCloseListener;
    } else {
      window.onkeydown = onkeydownBackup;
      onkeydownBackup = null;
    }
  }, [source]);

  function onCloseListener(e: KeyboardEvent) {
    if (e.keyCode === 27) {
      handleClose();
    }
  }

  function handleClose(event?: any) {
    if (event && event.target.tagName === "IMG") return;

    if (modalRef.current) {
      modalRef.current.style.opacity = "0";
    }

    setTimeout(() => {
      dispatch(setImageModal(false));
    }, 0);
  }

  if (!source) return null;
  return (
    <div
      ref={modalRef}
      className={"modal image-modal"}
      role="dialog"
      onClick={handleClose}
    >
      <div className="content">
        <button className="close-button" onClick={handleClose}>
          &times;
        </button>
        <img src={source} />
      </div>
    </div>
  );
}
