import { useAppSelector } from "redux/store";

interface LoaderProps {
  isPage?: boolean;
  isRelative?: boolean;
  className?: string;
}

export default function Loader({ className, isPage, isRelative }: LoaderProps) {
  const loading = useAppSelector((state) => state.ui.loader);

  if (!loading && !isPage) return null;

  return (
    <div
      className={`preloader ${className} ${
        isRelative ? "position-relative" : ""
      }`}
      style={{ padding: "20px" }}
    >
      <div
        className="spinner-border text-primary"
        style={{ width: "3rem", height: "3rem", margin: "20px" }}
        role="status"
      ></div>
    </div>
  );
}
