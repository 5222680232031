import { useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../imageWithBasePath";
import { useDispatch, useSelector } from "react-redux";
// import Notifications from "./Notifications";
import ProfileDropdown from "./ProfileDropdown";
import { useAppSelector } from "redux/store";
import {
  setExpandMenu,
  setMiniSidebar,
  setMobileSidebar,
  setThemeSettings,
} from "redux/uiSlice";
// import NavList from "./NavList";

const Header = () => {
  const dispatch = useDispatch();

  const settings = useAppSelector((state) => state.settings);
  const mobileSidebar = useAppSelector((state) => state.ui.mobileSidebar);
  const miniSidebar = useAppSelector((state) => state.ui.miniSideBar);
  const expandMenu = useSelector((state: any) => state.expandMenu);

  const toggleMobileSidebar = () => {
    dispatch(setMobileSidebar(!mobileSidebar));
  };
  const toggleMiniSidebar = () => {
    dispatch(setMiniSidebar(!miniSidebar));
  };
  const toggleExpandMenu = () => {
    dispatch(setExpandMenu(!expandMenu));
  };

  const [themeSetting, setThemeSetting] = useState(false);

  function handleLogout() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.pathname = "/login";
  }

  return (
    <>
      {/* Header */}
      <nav className="header d-print-none">
        {/* Logo */}
        <div className="header-left active">
          <Link to={"/"} className="logo logo-normal">
            {themeSetting ? (
              <ImageWithBasePath
                src={settings.logo || "assets/img/white-logo.svg"}
                className="white-logo"
                alt="Logo"
              />
            ) : (
              <ImageWithBasePath
                src={settings.logo || "assets/img/logo.png"}
                alt="Logo"
                style={{ objectFit: "contain", height: "50px" }}
              />
            )}
          </Link>
          <Link to={"/"} className="logo-small">
            <ImageWithBasePath
              src={settings.logo || "assets/img/logo.png"}
              alt="Logo"
            />
          </Link>
          <Link id="toggle_btn" to="#" onClick={toggleMiniSidebar}>
            <i className="ti ti-arrow-bar-to-left" />
          </Link>
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#sidebar"
          onClick={toggleMobileSidebar}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        <div className="header-user">
          <ul className="nav user-menu">
            {/* Search */}
            <li className="nav-item nav-search-inputs me-auto">
              {/* <div className="top-nav-search">
                <Link to="#" className="responsive-search">
                  <i className="fa fa-search" />
                </Link>
                <form className="dropdown">
                  <div className="searchinputs" id="dropdownMenuClickable">
                    <input type="text" placeholder="Search" />
                    <div className="search-addon">
                      <button type="submit">
                        <i className="ti ti-command" />
                      </button>
                    </div>
                  </div>
                </form>
              </div> */}
            </li>
            {/* /Search */}

            {/* <NavList
              themeSetting={themeSetting}
              setThemeSetting={setThemeSetting}
            /> */}

            {/* Email */}
            {/* <li className="nav-item nav-item-email nav-item-box">
              <Link to={route.email}>
                <i className="ti ti-message-circle-exclamation" />
                <span className="badge rounded-pill">14</span>
              </Link>
            </li> */}
            {/* /Email */}

            {/* <Notifications /> */}
            <ProfileDropdown />
          </ul>
        </div>
        {/* Mobile Menu */}
        {!mobileSidebar && (
          <div className="dropdown mobile-user-menu">
            <Link
              to="#"
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v" />
            </Link>
            <div className="dropdown-menu">
              {/* <Link className="dropdown-item" to={route.dealsDashboard}>
              <i className="ti ti-layout-2" /> Dashboard
            </Link> */}
              <Link className="dropdown-item" to={"/profile"}>
                <i className="ti ti-user-pin" /> Profil
              </Link>
              <Link
                className="dropdown-item"
                to={"#"}
                onClick={() => dispatch(setThemeSettings(true))}
              >
                <i className="fa fa-cog fa-w-16 fa-spin" /> Tema Ayarları
              </Link>
              <Link
                className="dropdown-item color-warning"
                state={{ color: "red" }}
                to={"#"}
                onClick={handleLogout}
              >
                <i className="ti ti-lock" /> Çıkış Yap
              </Link>
            </div>
          </div>
        )}
        {/* /Mobile Menu */}
      </nav>
    </>
  );
};

export default Header;
