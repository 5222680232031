import ALLRoutes from "./router/router";
import { useDispatch } from "react-redux";
import Loader from "components/common/loader";
import { DeleteModal } from "components/Modal/DeleteModal";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import ImageModal from "components/Modal/ImageModal";
import { DialogModal } from "components/Modal/DialogModal";
import { useAppSelector } from "redux/store";
import { setThemeSettings } from "redux/uiSlice";
import { useEffect } from "react";
import {
  CompanySettingsInterface,
  setSettings,
  setWebsiteReady,
} from "redux/settingsSlice";
import sendRequest from "helpers/service";
import handleError from "helpers/errorHandler";
import { getSubdomain } from "helpers/mixins";

const Feature = () => {
  const dispatch = useDispatch();
  const settings = useAppSelector((state) => state.settings);
  const themeOpen = useAppSelector((state) => state.ui.themeSettings);
  const headerCollapse = useAppSelector((state) => state.ui.headerCollapse);
  const mobileSidebar = useAppSelector((state) => state.ui.mobileSidebar);
  const miniSidebar = useAppSelector((state) => state.ui.miniSideBar);
  const expandMenu = useAppSelector((state) => state.ui.expandMenu);

  useEffect(() => {
    if (getSubdomain()) {
      sendRequest
        .get({
          url: "company",
        })
        .then((data: CompanySettingsInterface) => {
          document.title = data.name + " CRM";
          dispatch(setSettings(data));
        })
        .catch((err) => {
          console.log("@error", err);
          handleError(err);
        });
    } else {
      dispatch(setWebsiteReady());
    }
  }, []);

  if (!settings.isReady) return <Loader isPage />;
  return (
    <div>
      <div
        id="main-wrapper"
        className={`main-wrapper 
        ${headerCollapse ? "header-collapse" : ""} 
        ${mobileSidebar ? "slide-nav" : ""}
        ${miniSidebar ? "mini-sidebar" : ""}
        ${expandMenu ? "expanded-menu" : ""}`}
      >
        <ALLRoutes />
      </div>
      <div className="sidebar-overlay"></div>
      <div
        className={`sidebar-themeoverlay ${themeOpen ? "open" : ""}`}
        onClick={() => dispatch(setThemeSettings(!themeOpen))}
      />
      <ToastContainer position="top-right" />
      <Loader />
      <DeleteModal />
      <DialogModal />
      <ImageModal />
    </div>
  );
};

export default Feature;
