import { PropsWithChildren, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

interface ButtonProps {
  text?: string;
  theme?: ButtonThemeTypes;
  loader?: boolean;
  leftIcon?: string;
  onClick?: () => void;
  to?: string;
  style?: React.CSSProperties;
  color?: string;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  autoFocus?: boolean;
}

export type ButtonThemeTypes =
  | "primary"
  | "secondary"
  | "danger"
  | "light"
  | "text"
  | "success"
  | "info";

export default function Button(props: PropsWithChildren<ButtonProps>) {
  const ref = useRef<any>(null);

  useEffect(() => {
    if (props.autoFocus && ref.current) ref.current.focus();
  }, []);

  if (props.to) {
    return (
      <Link
        ref={ref}
        to={props.to}
        className={`btn btn-${props.theme || "primary"} ${props.className}`}
        onClick={props.onClick}
        style={props.style}
      >
        {props.loader ? (
          <div className="spinner-border" role="status"></div>
        ) : (
          <>
            {props.leftIcon && (
              <i className={props.leftIcon} style={{ marginRight: "4px" }} />
            )}
            {props.children}
            {props.text}
          </>
        )}
      </Link>
    );
  } else {
    return (
      <button
        ref={ref}
        disabled={props.loader || props.disabled}
        className={`btn btn-${props.theme || "primary"} ${props.className}`}
        onClick={props.onClick}
        style={{ ...props.style, color: props.color }}
        type={props.type || "button"}
      >
        {props.loader ? (
          <div className="spinner-border spinner-border-sm" role="status"></div>
        ) : (
          <>
            {props.leftIcon && (
              <i className={props.leftIcon} style={{ marginRight: "4px" }} />
            )}
            {props.children}
            {props.text}
          </>
        )}
      </button>
    );
  }
}
