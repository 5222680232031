interface CheckboxProps extends Partial<HTMLInputElement> {
  checked: boolean;
  onChange: (value: boolean) => void;
  name?: string;
  warn?: boolean;
  label?: string;
  containerClass?: string;
  warning?: string;
}

export default function Checkbox(props: CheckboxProps) {
  return (
    <label className={"checkboxs " + props.containerClass}>
      <input
        type="checkbox"
        name={props.name}
        checked={props.checked}
        onChange={(e) => props.onChange(e.target.checked)}
      />
      <span
        className="checkmarks"
        style={{ border: "1px solid #9b9b9b" }}
      ></span>
      {props.label}
    </label>
  );
}
