//objenin her bir elemanı pageType
//obje içindeki elemanlar ise sayfalardır.
//sayfaların sahip olduğu boolean değer ise sayfayı temsil eden asıl izindir. Eğer bu izin yoksa sayfa router'de render edilmez.
const routePermissions: any = {
  mail_t: {
    mail_t: true,
  },
  customer_dashboard: {
    customer_dashboard: true,
  },
  prim_dashboard: {
    prim_dashboard: true,
  },
  announce: {
    announce: true,
  },
  systemcx: {
    systemcx: true,
  },
  customer: {
    customer: true,
    c_visit: false,
    user: false,
    activity: false,
  },
  c_calls: {
    c_calls: true,
    customer: false,
  },
  c_visit: {
    c_visit: true,
    customer: false,
    user: false,
  },
  profile: {
    user: false,
    file: false,
  },
  user: {
    user: true,
    roles: false,
    activity: false,
  },
  role: {
    role: true,
  },
  product: {
    product: true,
  },
  offer: {
    offer: true,
    product: false,
    customer: false,
  },
  exchange: {
    exchange: true,
  },
  settings: {
    settings: true,
    comp_settings: false,
  },
};

export default routePermissions;
