import { languages } from "helpers/refs";
import { valdiateMultilanguageText } from "mixins/validation";
import React, {
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import c from "./input.module.css";
import classcat from "classcat";
import { DropdownOptions } from "interface";

interface InputProps {
  value: any;
  onChange: (value: any, currentLanguage?: any) => void;
  onPaste?: (value: any) => void;
  placeholder?: string;
  name?: string;
  warn?: boolean;
  validateMutliLanguage?: boolean;
  warning?: string;
  label?: string;
  currentLanguage?: string;
  mandatory?: boolean;
  disabled?: boolean;
  autocomplete?: any;
  min?: number;
  max?: number;
  maxLength?: number;
  containerStyle?: CSSProperties;
  containerClass?: string;
  inputClass?: string;
  style?: any;
  dropdown?: {
    label: string;
    value: any;
    onChange: (value: any) => void;
    options: DropdownOptions[];
  };
  type?:
    | "text"
    | "tel"
    | "password"
    | "email"
    | "phone"
    | "textarea"
    | "number"
    | "date"
    | "datetime-local";
  leftIcon?: string;
  rightIcon?: string;
  optins?: {
    //TODO: ihtiyaç olursa burayı düzenle
    text: string;
  };
}

const Input = forwardRef((props: InputProps, ref) => {
  const [passwordState, setPasswordState] = useState(false);

  useImperativeHandle(ref, () => ({
    setPasswordState: (state: boolean) => setPasswordState(state),
  }));

  let warn = props.warn;
  if (props.validateMutliLanguage) {
    warn = valdiateMultilanguageText(props.value);
  }
  return (
    <div
      style={props.containerStyle}
      className={classcat([
        c.container,
        props.dropdown && c.dropdownContainer,
        "form-wrap",
        props.containerClass,
      ])}
    >
      <div className="d-flex justify-content-between align-items-center">
        {props.label && (
          <label
            className="col-form-label mb-0"
            style={{
              color: warn ? "red" : "rgb(70, 70, 70)",
            }}
          >
            {props.label}
            {props.mandatory && <span className="text-danger">*</span>}
          </label>
        )}
        {props.optins && (
          <div className="status-toggle small-toggle-btn d-flex align-items-center">
            <span className="me-2 label-text">{props.optins.text}</span>
            <input
              type="checkbox"
              id="user1"
              className="check"
              defaultChecked={true}
            />
            <label htmlFor="user1" className="checktoggle" />
          </div>
        )}
      </div>

      {props.type === "password" && (
        <div
          className="icon-form-end"
          style={{ top: "21px", fontSize: "16px" }}
        >
          <span
            className={"form-icon"}
            onClick={() => setPasswordState(!passwordState)}
          >
            <i
              style={{ fontSize: "16px" }}
              className={passwordState ? "bi bi-eye" : "bi bi-eye-slash"}
            />
          </span>
        </div>
      )}
      <div
        className={classcat([
          "form-wrap",
          "mb-0",
          (props.leftIcon || props.rightIcon) && "icon-form",
          warn && "icon-form-end",
        ])}
      >
        {props.leftIcon && (
          <span className="form-icon">
            <i className={props.leftIcon}></i>
          </span>
        )}

        <div className="d-flex align-items-center">
          {props.dropdown && (
            <select
              className="input-dropdown icon-form"
              onChange={(e) => props.dropdown?.onChange(e.target.value)}
            >
              {props.dropdown.options.map((option) => (
                <option
                  key={"input-option-" + option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
          )}
          {props.type === "textarea" ? (
            <textarea
              className={classcat(["form-control", warn && c.warning])}
              rows={5}
              value={
                props.currentLanguage
                  ? props.value[props.currentLanguage]
                  : props.value
              }
              onChange={(e) =>
                props.onChange(e.target.value, props.currentLanguage)
              }
              disabled={props.disabled}
            />
          ) : (
            <input
              maxLength={props.maxLength}
              value={
                props.currentLanguage
                  ? props.value[props.currentLanguage]
                  : props.value
              }
              onChange={(e) =>
                props.onChange(e.target.value, props.currentLanguage)
              }
              onPaste={props.onPaste}
              placeholder={props.placeholder}
              className={classcat([
                "form-control",
                props.inputClass,
                warn && c.warning,
              ])}
              name={props.name}
              type={passwordState ? "text" : props.type || "text"}
              disabled={props.disabled}
              autoComplete={props.autocomplete}
              style={{
                color: "black",
              }}
              min={props.min}
              max={props.max}
            />
          )}
        </div>
        {props.rightIcon && (
          <span className="form-icon">
            <i className={props.rightIcon}></i>
          </span>
        )}
        {warn && !props.leftIcon && (
          <span className="form-icon">
            <i
              className="bi bi-exclamation-triangle-fill"
              style={{ color: "red" }}
            ></i>
          </span>
        )}
      </div>
      {props.validateMutliLanguage &&
        valdiateMultilanguageText(props.value) && (
          <div className="mt-1 text-danger font-bold" style={{ fontSize: 12 }}>
            {`${props.warning || "Bu alan zorunludur."}
            (${languages.current
              ?.filter((lang) => !props.value[lang.isoCode])
              .map((lang) => lang.name)
              .join(", ")})`}
          </div>
        )}
      {warn && !props.validateMutliLanguage && (
        <div className="mt-1 text-danger font-bold" style={{ fontSize: 12 }}>
          {props.warning || "Bu alan zorunludur."}
        </div>
      )}
    </div>
  );
});

// export default Input;
export default React.memo(Input);
