import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface CompanySettingsInterface {
  name: string;
  customer_id: string;
  email: string;
  phone: string;
  fax: string;
  website: string;
  commercial_title: string;
  customer_prim_limit: number;
  customer_prim_amount: number;
  logo: string;
  horizontal_logo?: string | null;
  invoice_logo?: string | null;
  address: string;
  updated_at: string;
  created_at: string;
  isReady: boolean;
}

const initialState: CompanySettingsInterface = {
  name: "",
  commercial_title: "",
  customer_prim_limit: 0,
  customer_prim_amount: 0,
  customer_id: "",
  email: "",
  phone: "",
  fax: "",
  website: "",
  logo: "",
  address: "",
  horizontal_logo: "",
  invoice_logo: "",
  created_at: "",
  updated_at: "",
  isReady: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    setSettings: (
      state,
      { payload }: PayloadAction<CompanySettingsInterface>
    ) => {
      state = payload;
      state.isReady = true;

      return state;
    },
    setWebsiteReady: (state: any) => {
      state.isReady = true;

      return state;
    },
  },
});

export const { setSettings, setWebsiteReady } = settingsSlice.actions;

export default settingsSlice.reducer;
