import Button from "components/Button";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/store";
import { setDialogModal } from "redux/uiSlice";
import CenteredModal from "./CenteredModal";
import { useState } from "react";
import Input from "components/form/Input";

export function DialogModal() {
  const dispatch = useDispatch();
  const props = useAppSelector((state) => state.ui.dialogModal);
  const [input, setInput] = useState("");
  const [attempted, setAttempted] = useState(false);

  function onClose() {
    dispatch(setDialogModal(false));
    setInput("");
    setAttempted(false);
    if (props && props.onClose) {
      props.onClose();
    }
  }

  if (!props) return null;
  return (
    <CenteredModal icon={props.icon} title={props.title} onClose={onClose}>
      <span
        style={{ fontSize: 14, color: "black" }}
        dangerouslySetInnerHTML={{ __html: props.message }}
      />
      {props.input && (
        <div className="row mt-3">
          <Input
            label={props.input.label}
            value={input}
            onChange={setInput}
            warn={
              attempted &&
              (props.input.onValidate
                ? !props.input.onValidate(input)
                : input.length === 0)
            }
            warning={props.input.warningText}
          />
        </div>
      )}
      <div className="row mt-4">
        <div className="col-md-12 d-flex justify-content-end">
          {props.cancel && (
            <Button
              theme="light"
              style={{ marginRight: "10px" }}
              text={props.cancel?.text || "Kapat"}
              onClick={() => {
                if (props.cancel?.onClick) props.cancel.onClick();
                onClose();
              }}
              autoFocus
            />
          )}
          {props.button && (
            <Button
              style={{ width: "40%" }}
              theme="danger"
              text={props.button.text}
              disabled={
                props.input?.mandatory && attempted && input.length === 0
              }
              onClick={() => {
                if (
                  props.input?.mandatory &&
                  (props.input.onValidate
                    ? !props.input.onValidate(input)
                    : input.trim().length === 0)
                ) {
                  setAttempted(true);
                  return;
                }

                if (props.button?.onClick) props.button.onClick(input);
                setInput("");
                onClose();
              }}
            />
          )}
        </div>
      </div>
    </CenteredModal>
  );
}
