import { DropdownOptions } from "interface";
import { toast } from "react-toastify";

export function slugify(name: string, underscore?: boolean) {
  const nameNormalized = name.normalize("NFD").toLowerCase();
  const slug = nameNormalized.replace(/[^a-zA-Z0-9]+/g, underscore ? "_" : "-");

  return slug.trim();
}

export function slugifyFileName(name: string) {
  return name
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^a-z0-9.-]+/g, "") // Remove special characters except dot and hyphen
    .replace(/\.+/g, ".") // Collapse consecutive dots
    .replace(/^-+|-+$/g, ""); // Trim leading and trailing hyphens
}

export function formatFileSize(bytes: number) {
  if (bytes === 0) return "0 Bytes";

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
}

export function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
}

export function getSubdomain() {
  const hostname = window.location.hostname;

  const parts = hostname.split(".");
  if (
    (hostname.includes("localhost") && parts.length > 1) ||
    parts.length > 2
  ) {
    return parts[0]; // Return the first element (subdomain)
  } else {
    return null; // No subdomain present
  }
}

export function toastMessage(
  text: string,
  type: "default" | "error" | "success" | "warning" | "info"
) {
  toast(text, {
    theme: "colored",
    type: type,
  });
}

export function prettifyPhone(phone: string) {
  // Remove any non-digit characters
  // phone = phone.replace(/\D/g, "");

  // Ensure the phone number has exactly 10 digits
  if (phone.length < 10) {
    return "Invalid phone number";
  }

  // prettier-ignore
  if (phone.startsWith("+")) {
    return `${phone.substring(0, 3)} (${phone.substring(3, 6)}) ${phone.substring(6,9)}-${phone.substring(9)}`;
  } else {
    return `(${phone.substring(0, 3)}) ${phone.substring(
      3,
      6
    )}-${phone.substring(6)}`;
  }
}

export function prettifyIBAN(iban: string) {
  if (!iban) return "";
  iban = iban.replace(/\s+/g, "");
  return iban.replace(/(.{4})/g, "$1 ").trim();
}

function addEmptyStateOption(
  setter: any,
  options: DropdownOptions[],
  emptyStateOption: DropdownOptions
) {
  return [emptyStateOption, ...options];
}
