import { Link } from "react-router-dom";
import ImageWithBasePath from "../imageWithBasePath";
import { useAppSelector } from "redux/store";
import { useDispatch } from "react-redux";
import { setThemeSettings } from "redux/uiSlice";

export default function ProfileDropdown() {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user.data);

  function handleLogout() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.pathname = "/login";
  }

  return (
    <li className="nav-item dropdown has-arrow main-drop">
      <Link to="#" className="nav-link userset" data-bs-toggle="dropdown">
        <span className="user-info">
          <span className="user-letter">
            <ImageWithBasePath
              src={user.avatar || "assets/img/profiles/avatar-20.jpg"}
              alt="Profile"
            />
          </span>
          <span className="badge badge-success rounded-pill" />
        </span>
      </Link>
      <div className="dropdown-menu menu-drop-user">
        <div className="profilename">
          {/* <Link className="dropdown-item" to={route.dealsDashboard}>
            <i className="ti ti-layout-2" /> Dashboard
          </Link> */}
          <Link className="dropdown-item" to={"/profile"}>
            <i className="ti ti-user-pin" /> Profilim
          </Link>
          <Link
            className="dropdown-item"
            to={"#"}
            onClick={() => dispatch(setThemeSettings(true))}
          >
            <i className="fa fa-cog fa-w-16 fa-spin" /> Tema Ayarları
          </Link>
          <Link
            className="dropdown-item color-warning"
            to="#"
            state={{ color: "red" }}
            onClick={handleLogout}
          >
            <i className="ti ti-lock" /> Çıkış Yap
          </Link>
        </div>
      </div>
    </li>
  );
}
